<template>
  <div
  >
    <v-card
      class="py-6 px-6 mt-3 ml-3"
      rounded="lg"
      elevation="0"
    >
      <v-row>
        <v-col flex>
          <div
            class="back-to-projects"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('Back to Milestone Info') }}
            </p>
          </div>
        </v-col>
      </v-row>
      <template v-if="showTestRunsView">
        <div class="d-flex align-center justify-space-between mt-5">
          <div
            class="d-flex align-center"
          >
            <div v-if="isEditing">
              <v-text-field
                v-model="selectedMilestone.name"
                class="title-edit-field font-weight-bold fs-24px text-theme-base edit-title-input pt-0 mt-0"
                hide-details
                @keyup.enter="saveHeader"
                @blur="saveHeader"
              />
            </div>
            <div v-else>
              <div class="d-flex align-center">
                <p class="text-start font-weight-bold fs-24px mb-0 text-theme-base mr-3">
                  {{ selectedMilestone.name }}
                </p>
                <PencilIcon @click="editHeader" />
              </div>
            </div>
          </div>
          <div
            class="d-flex align-center"
          >
            <v-row
              class="align-center"
            >
              <!-- Label Column -->
              <div
                class="d-flex align-center"
              >
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('status') }}:
                </v-label>
              </div>
              <!-- Select Column -->
              <v-col
                cols="auto"
                class="pb-4"
              >
                <v-select
                  v-model="selectedMilestone.customFields.status"
                  :items="statuses"
                  item-text="name"
                  item-value="type"
                  class="rounded-lg select-input-none select-custom-milestone field-theme custom-prepend"
                  background-color="#F9F9FB"
                  :placeholder="$t('status')"
                  append-icon="mdi-chevron-down"
                  :menu-props="{ offsetY: true }"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="d-flex align-center">
          <div v-if="isEditing">
            <v-text-field
              v-model="selectedMilestone.description"
              class="subdescription-edit-field"
              hide-details
              @keyup.enter="saveHeader"
              @blur="saveHeader"
            />
          </div>
          <div v-else>
            <div class="d-flex">
              <p class="text-start display-10 my-2">
                {{ selectedMilestone.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <p class="text-start fs-16px mb-0 mr-2">
            {{ $t('milestone.create_milestone.dueDate') + ':' }}
          </p>
          <v-menu
            v-model="form.dueDate"
            :close-on-content-click="false"
            :max-width="'290px'"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                color="blue"
                class="date-picker-field pt-0 mt-0 fs-16px fw-semibold"
                :value="dueDate"
                readonly
                :rules="[validateDueDate]"
                v-on="on"
                hide-details
              />
            </template>
            <v-date-picker
              v-model="dueDate"
              @change="form.dueDate = false"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <p 
          v-if="!validateDueDate(dueDate)" 
          class="red--text mb-0"
        >
          {{ $t('milestone.create_milestone.dueDateError') }}
        </p>  
        <div class="mt-4 d-flex">
          <v-chip
            :class="{ 'blue--text': tableTestType === 'plans' }"
            width="200px"
            :color="tableTestType === 'plans' ? 'blue-light' : 'gray-light'"
            label
            @click="changeTestType('plans')"
          >
            <div class="font-weight-bold px-2">
              {{ $t('testPlans') }} <span class="ml-1">{{ getActivePlanItemsCount }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              :class="{ 'blue--text': tableTestType === 'runs' }"
              width="200px"
              :color="tableTestType === 'runs' ? 'blue-light' : 'gray-light'"
              label
              @click="changeTestType('runs')"
            >
              <div class="font-weight-bold px-2">
                {{ $t('testRuns') }} <span class="ml-1">{{ getActiveRunItemsCount }}</span>
              </div>
            </v-chip>
          </div>
        </div>
      </template>
      </v-card>
      
      <v-card
        class="py-6 px-6 mt-3 ml-3"
        rounded="lg"
        elevation="0"
      >
        <div class="fs-24px text-start font-weight-bold">{{ selectedTestType }}</div>
        <div class="mt-6 d-flex">
          <v-chip
            :class="{ 'blue--text': tableFilter === 'all' }"
            width="200px"
            :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
            label
            @click="changeFilter('all')"
          >
            <div class="font-weight-bold px-2">
              {{ $t('all') }} <span class="ml-1">{{ isTableTypePlans ? planFilterCount :  runFilterCount }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              :class="{ 'blue--text': tableFilter === 'selected' }"
              width="200px"
              :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
              label
              @click="changeFilter('selected')"
            >
              <div class="font-weight-bold px-2">
                {{ $t('selected') }} <span class="ml-1">{{ isTableTypePlans ? selectedPlanLength :  selectedRunLength }}</span>
              </div>
            </v-chip>
          </div>
        </div>
        <v-row class="align-center mt-3">
          <v-col
            cols="6"
            sm="6"
            class="py-0"
          >
            <div class="d-flex flex-row justify-start align-center">
              <v-responsive
                class="ma-0"
                max-width="344"
              >
                <v-text-field
                  v-model="searchFilter"
                  class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                  :placeholder="$t('searchByName')"
                  height="40"
                  background-color="#F9F9FB"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <SearchIcon />
                  </template>
                </v-text-field>
              </v-responsive>
              <ProjectUserFilter
                :tags="tags" 
                @applyFilters="applyFilters"
              />
            </div>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            class="d-flex justify-end"
          >
          <div :class="tableTestType === 'runs' ? 'd-block' : 'd-none'">
            <SettingsMenu 
              table-type="milestoneEditRuns" 
            />
          </div>
          <div :class="tableTestType === 'plans' ? 'd-block' : 'd-none'">
            <SettingsMenu 
              table-type="milestoneEditPlans" 
            />
          </div>
          </v-col>
        </v-row>
        <v-row
          v-if="isAnyFilterApplied"
          class="mt-3"
        >
          <v-chip
            v-for="role in appliedFilters.roles"
            :key="role.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('role') }}: {{ role.name }}
          </v-chip>
          <v-chip
            v-for="project in appliedFilters.projects"
            :key="project.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('project') }}: {{ project.name }}
          </v-chip>
          <v-chip
            v-for="tag in appliedFilters.tags"
            :key="tag.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('project') }}: {{ tag.title }}
          </v-chip>
          <v-btn
            class="text-capitalize mt-1"
            color="blue"
            text
            @click="clearAll"
          >
            {{ $t('clearAll') }}
          </v-btn>
        </v-row>

        <MileatoneTestPlanTable
          v-if="tableTestType === 'plans'"
          :plans-data="filteredTestPlans"
          :table-filter="tableFilter"
          :filtered-headers="filteredPlanHeaders"
          :filtered-items="filteredTestPlans"
          />

        <MilestoneTestRunTable
        v-if="tableTestType === 'runs'"
          :runs-data="filteredTestRuns"
          :table-filter="tableFilter"
          :filtered-headers="filteredRunHeaders"
          :filtered-items="filteredTestRuns"
        />
      
      <v-row
        justify="end"
        class="mt-4"
      >
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-btn
              v-if="showTestRunsView"
              depressed
              color="gray-100"
              height="40"
              class="text-capitalize rounded-lg font-weight-bold black--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="handleBackClick"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              v-if="showTestRunsView && toBeSelectedRunLength && tableFilter === 'all' ||
              showTestRunsView && toBeSelectedPlanLength && tableFilter === 'all'"
              depressed
              color="blue"
              height="40"
              class="text-capitalize rounded-lg font-weight-bold white--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="addTestRuns"
            >
              {{ $t('projects.create_project.add') }} {{ `(${isTableTypePlans ? toBeSelectedPlanLength : toBeSelectedRunLength})` }}
            </v-btn>
            <v-btn
              v-if="showTestRunsView && toBeSelectedRunLength && tableFilter === 'selected'
              || showTestRunsView && toBeSelectedPlanLength && tableFilter === 'selected'"
              depressed
              color="blue"
              height="40"
              class="text-capitalize rounded-lg font-weight-bold white--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="removeTestRuns"
            >
              {{ $t('projects.create_project.remove') }} {{ `(${isTableTypePlans ? toBeSelectedPlanLength : toBeSelectedRunLength})` }}
            </v-btn>
            <v-btn
              v-if="!showTestRunsView || (showTestRunsView && !toBeSelectedRunLength && !toBeSelectedPlanLength)"
              depressed
              color="primary"
              height="40"
              class="text-capitalize btn-theme rounded-lg mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="updateMilestone"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
  
    <ProjectDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('milestone.edit_milestone.close_dialog.title')"
      :content="$t('milestone.edit_milestone.close_dialog.title')"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
    <RemoveTestRunDialog
      v-model="showConfirmRemoveTestRunDialog"
      :title="$t('milestone.close_remove_dialog.title')"
      :content="$t('milestone.close_remove_dialog.title')"
      :content_part2="$t('milestone.close_remove_dialog.content_part1')"
      :btn_label="$t('milestone.close_remove_dialog.remove_button')"
      @close="handleCloseClick"
      @handleConfirmClick="handleRemoveConfirmClick"
    />
  </div>
</template>


<script>
import { mapGetters, mapActions as projectMapAcions } from 'vuex';
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import MilestoneTestRunTable from '@/components/Milestone/MilestoneTestRunTable.vue';
import MileatoneTestPlanTable from '@/components/Milestone/MilestoneTestPlanTable.vue';
import ProjectUserFilter from '@/components/Milestone/MilestoneTestRunFilter.vue';
import RemoveTestRunDialog from '@/components/Milestone/MilestoneEditRemoveDialog.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import { testruns } from '@/constants/data.js';
import { createNamespacedHelpers } from 'vuex';
import { showSuccessToast } from '@/utils/toast';
import { dateMixin } from '@/mixins/date.js';
import SearchIcon from '@/assets/svg/search-icon.svg';
import PencilIcon from '@/assets/svg/pencil.svg';
import makeTagService from '@/services/api/tag';
import { showErrorToast } from '@/utils/toast';

const { mapActions, mapState } = createNamespacedHelpers('milestone');
const  { mapState: mapRunState, mapActions: mapRunActions } = createNamespacedHelpers('run');
const { mapState: mapPlanState, mapActions: mapPlanActions } = createNamespacedHelpers('plan');

export default {
  name: 'MilestoneEditView',
  components: {
    ProjectDiscardDialog,
    MilestoneTestRunTable,
    MileatoneTestPlanTable,
    ProjectUserFilter,
    RemoveTestRunDialog,
    SearchIcon,
    PencilIcon,
    SettingsMenu,
  },
  mixins: [dateMixin],
  data() {
    return {
      imageSrc: '',
      users: testruns,
      oldMilestone: null,
      form: {
        projectName: '',
        description: '',
        users: [],
        dueDate: false,
      },
      isColumnFilter: false,
      showConfirmRemoveTestRunDialog: false,
      showConfirmBackDialog: false,
      showConfirmRemoveDialog: false,
      showTestRunsView: true,
      toBeSelectedUser: [],
      selectedTestRuns: [],
      testRuns: [],
      testPlans: [],
      tableFilter: 'all',
      tableTestType: 'plans',
      searchFilter: '',
      tags: [],
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      mainFilters: {
        selectedRoles: [],
        testCases: [0, 1000],
        users: [0, 100],
        selectedTags: [],
        dateRange: {
          start: '',
          end: '',
        },
        progress: [0, 100],
      },
      mainFilter: false,
      runHeaders: [],
      planHeaders: [],
      startDate: new Date().toISOString().substr(0, 10),
      dueDate: new Date().toISOString().substr(0, 10),
      isEditing: false,
      statuses: ['Active', 'Upcoming', 'Blocked'],
    };
  },
  computed: {
    ...mapState(['selectedMilestone']),
    ...mapRunState({
      runItems: 'items'
    }),
    ...mapPlanState({
      planItems: 'items'
    }),
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    getActivePlanItems() {
      return this.planItems?.filter((plan) => !plan.archivedAt);
    },
    getActiveRunItems() {
      return this.runItems?.filter((run) => !run.archivedAt);
    },
    getActivePlanItemsCount() {
      return this.getActivePlanItems.length;
    },
    getActiveRunItemsCount() {
      return this.getActiveRunItems.length;
    },
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    selectedTestType() {
      const testType = {
        plans: this.$t('testPlans'),
        runs: this.$t('testRuns')
      }
      return testType[this.tableTestType];
    },
    toBeSelectedRunLength() {
      return this.filteredTestRuns?.filter((run) => run.toBeSelected).length;
    },
    toBeSelectedPlanLength() {
      return this.filteredTestPlans?.filter((plan) => plan.toBeSelected).length;
    },
    selectedRunLength() {
      return this.filteredTestRuns?.filter((run) => run.selected).length;
    },
    selectedPlanLength() {
      return this.filteredTestPlans?.filter((plan) => plan.selected).length;
    },
    runFilterCount() {
      return this.getActiveRunItemsCount - this.selectedRunLength;
    },
    planFilterCount() {
      return this.getActivePlanItemsCount - this.selectedPlanLength;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
    isTableTypePlans() {
      return this.tableTestType === 'plans';
    },
    filteredRunHeaders() {
      return this.runHeaders?.filter((header) => header.checked);
    },
    filteredPlanHeaders() {
      return this.planHeaders?.filter((header) => header.checked);
    },
    filteredMenuHeaders() {
      return this.runHeaders?.filter((header) => header.text != 'Actions');
    },
    hasUnchecked() {
      return this.filteredMenuHeaders.some(item => !item. checked);
    },
    applyMainFilters() {
      return (items) => {
        const { selectedRoles, testCases, dateRange, selectedTags, users } = this.mainFilters;

        return items.filter(item => {
          if (selectedRoles.length > 0 && !selectedRoles.includes(item.priority)) {
            return false;
          }
          if (item.testcases < testCases[0] || item.testcases > testCases[1]) {
            return false;
          }
          if (dateRange.start && dateRange.end) {
            const itemDate = new Date(item.creationdate);
            const startDate = new Date(dateRange.start);
            const endDate = new Date(dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (selectedTags.length > 0 && !selectedTags.includes(item.tag)) {
            return false;
          }
          if (item.progress < users[0] || item.progress > users[1]) {
            return false;
          }
          return true;
        });
      };
    },
    filteredTestRuns() {
      return this.filteredItems(this.testRuns);
    },

    filteredTestPlans() {
      return this.filteredItems(this.testPlans);
    },

    filteredItems() {
      return (items) => {
        if (this.searchFilter && !this.mainFilter) {
          return items.filter(item => this.matchesFilter(item));
        } else if (this.mainFilter && !this.searchFilter) {
          return this.applyMainFilters(items);
        } else if (this.mainFilter && this.searchFilter) {
          const mainFiltered = this.applyMainFilters(items);
          return mainFiltered.filter(item => this.matchesFilter(item));
        }
        return items;
      };
    },
    validateDueDate() {
      return (value) => {
        if (new Date(value) < new Date(this.selectedMilestone.customFields.startDate)) {
          return false; 
        }
        return true;
      };
    },
  },
  watch: {
    currentOrg: 'refreshData',
    selectedMilestone(newValue) {
      if (newValue) {
        this.dueDate = this.formatDate(newValue.dueAt);
      }
    },
    filteredTestRuns(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchRuns();
      }
    },
  },
  mounted() {
    this.refreshData();
    this.getTags();
  },
  async created() {
    if(!this.dynamicHeaders.milestoneEditRuns) {
      this.initializeHeaders({ type: 'milestoneEditRuns' });
    }
    this.runHeaders = this.dynamicHeaders.milestoneEditRuns;

    if(!this.dynamicHeaders.milestoneEditPlans) {
      this.initializeHeaders({ type: 'milestoneEditPlans' });
    }
    this.planHeaders = this.dynamicHeaders.milestoneEditPlans;

    await this.findMilestone(this.$route.params.handle);

    const handle = this.$route.params.handle;
    await this.getAllTestRuns({ handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 });
    await this.getAllTestPlans({ handle, perPage: 99999, currentPage: 0, key: this.$route.params.key });
    this.copyTestRunItems();
    this.copyTestPlanItems();
    this.setSelectedMilestoneTestRuns()
  },
  methods: {
    ...mapActions(['update', 'find']),
    ...mapRunActions(['getAllTestRuns']),
    ...mapPlanActions(['getAllTestPlans']),
    ...projectMapAcions("headers", ['initializeHeaders']),
    handleRemoveConfirmClick() {
    },
    editHeader() {
      this.isEditing = true;
    },
    saveHeader() {
      this.isEditing = false;
    },
    copyTestRunItems() {
      this.testRuns = this.getActiveRunItems;
    },
    copyTestPlanItems() {
      this.testPlans = this.getActivePlanItems;
    },
    updateMilestone() {
      if (!this.validateDueDate(this.dueDate)) return;
      const item = "";
      const payload = {
        name: this.selectedMilestone.name,
        description: this.selectedMilestone.description,
        dueDate: new Date(this.dueDate),
        runIds: this.selectedTestRuns.map((e) => e.uid),
        customFields: this.selectedMilestone.customFields
      };
      this.update({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        milestoneId: this.selectedMilestone.uid,
        payload: payload,
      }).then(() => {
        showSuccessToast(this.$swal, this.$t('milestone.update_milestone_success'));
        this.$router.push({
          name: 'Milestones',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
          },
        });
      }).catch(error => {
        console.error('Failed to update milestone:', error);
      });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async getTags() {
      const handle = this.$route.params.handle;
      const tagService = makeTagService(this.$api);
        try {
          const response = await tagService.getTags(handle);
          this.tags = response.data
        } catch (err) {
          showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
          return [];
        }
    }, 
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleRemoveClick() {
      this.showConfirmRemoveDialog = true;
    },
    handleConfirmRemoveClick() {
      this.showConfirmRemoveDialog = false;
      this.removeTestRuns();
    },
    handleCloseRemoveClick() {
      this.showConfirmRemoveDialog = false;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'Milestones',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
      });
    },
    handleConfirmOpenClick(status) {
      this.$router.push({
        name: 'MilestoneCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
      });
      this.handleCloseClick();
    },
    closeShowTestRunsView() {
      this.showTestRunsView = false;
      this.toBeSelectedUser = [];
      this.$router.push({
        name: 'MilestoneCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
      });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    changeTestType(type) {
      this.tableTestType = type;
    },
    addTestRuns() {
      if(this.isTableTypePlans){
        this.testPlans.forEach((testPlan) => {
          if (testPlan.toBeSelected) {
            let index = this.selectedTestRuns.findIndex((item) => item.uid == testPlan.uid)
            if (index < 0) {
              this.selectedTestRuns.push(testPlan)
              this.$set(testPlan, 'selected', true);
              this.$set(testPlan, 'toBeSelected', false);
            } else {
              this.$set(testPlan, 'toBeSelected', false);
            }
          }
        });
      } else {
        this.testRuns.forEach((testRun) => {
        if (testRun.toBeSelected) {
          let index = this.selectedTestRuns.findIndex((item) => item.uid == testRun.uid)
          if (index < 0) {
            this.selectedTestRuns.push(testRun)

            this.$set(testRun, 'selected', true);
            this.$set(testRun, 'toBeSelected', false);
          } else {
            this.$set(testRun, 'toBeSelected', false);
          }
        }
      });
      }
      
    },
    removeTestRuns() {
      if(this.isTableTypePlans){
        this.testPlans.forEach((testPlan) => {
          if (testPlan.toBeSelected) {
            const index = this.selectedTestRuns.findIndex((item) => item.uid == testPlan.uid);
            if (index >= 0) {
              this.selectedTestRuns.splice(index, 1)
            }

            this.$set(testPlan, 'selected', false);
            this.$set(testPlan, 'toBeSelected', false);
          }
        });
      } else {
        this.testRuns.forEach((testRun) => {
        if (testRun.toBeSelected) {
          const index = this.selectedTestRuns.findIndex((item) => item.uid == testRun.uid);
          if (index >= 0) {
            this.selectedTestRuns.splice(index, 1)
          }

          this.$set(testRun, 'selected', false);
          this.$set(testRun, 'toBeSelected', false);
        }
      });
      }
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.mainFilters = filters;
      this.mainFilter = true;

    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      return item.name.toLowerCase().includes(lowerCaseFilter);
    },
    async searchRuns() {
      if (this.searchFilter) {
        await this.$store.dispatch('run/searchTestRun', {
          handle: this.currentOrg.handle,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        this.refreshData();
      }
    },
    async refreshData() {
      await this.findMilestone(this.$route.params.handle);
    },
    findMilestone(handle) {
      this.find({ handle: handle, projectKey: this.$route.params.key, uid: this.$route.params.id });
    },
    setSelectedMilestoneTestRuns() {
      if (this.selectedMilestone) {
        this.testRuns.forEach((testRun) => {
          const index = this.selectedMilestone.testRuns?.findIndex((item) => item.uid == testRun.uid);

          if (index >= 0) {
            this.selectedTestRuns.push(testRun)
            this.$set(testRun, 'selected', true);
          }
        })
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.search_input {
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 300px;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}

.title-edit-field {
  font-size: 34px;
}

.subdescription-edit-field {
  font-size: 17px;
  width: 600px;
}

.date-picker-field {
  width: 100px
}

.text-theme-label {
  color: #667085!important;
}

.select-custom-milestone {
  height: 40px;
  width: 130px;
  border-radius: 6px;
  color: #0C111D;
  padding-top: 5px;
  background-color: #F9FAFB;
}
</style>
